.appContainer {
  isolation: isolate;
  --navBar-height: 72px;
  --horizontal-padding: 32px;
  --sidenav-verticalPadding: 40px;
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-template-rows: var(--navBar-height) 1fr;
  grid-template-areas:
    'navbar navbar'
    'sidenav main';
  grid-column: 0px;
  grid-row-gap: 0px;
  height: 100vh;
}

.navbar {
  z-index: 40;
  grid-area: navbar;
  color: inherit;
}

.sidenav {
  grid-area: sidenav;
}

.appBody {
  grid-area: main;
  display: flex;
  flex-direction: column;
  width: auto;
  overflow-y: auto;
}

@media (max-width: 767px) {
  .app-body {
    padding: 0;
  }
  .mobileModal {
    width: 90vw;
    max-width: 400px;
  }
  .forgotPasswordModal,
  .emailSentModal,
  .sessionExpiredModal {
    width: 90vw;
  }
}
.sessionExpiredModal :global .log-in-form-basic__submit-button {
  background-color: #548127;
}
.sessionExpiredModal :global .log-in-form__forgot-button {
  color: #548127;
}

.offlineImageContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  height: 700px;
}
.offlineImageContainer .offlineMessageContainer {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.offlineImageContainer .offlineMessageContainer .offlinePrimaryMessage {
  font-size: 24px;
  font-weight: 700;
}
.offlineImageContainer .offlineMessageContainer .offlineSecondaryMessage {
  margin-top: 10px;
}
.offlineImageContainer .offlineImage {
  width: 600px;
}

@media (max-width: 600px) {
  .offlineImageContainer .offlineImage {
    width: 300px;
  }
  .offlineImageContainer .offlineSecondaryMessage {
    font-size: 14px;
  }
}
